import { NextPage } from 'next'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

const NotFoundPage: NextPage = () => {
  const { t } = useTranslation()

  return (
    <div className='flex h-full w-full items-center justify-center bg-purple text-white'>
      <div className='flex max-w-[600px] flex-col items-center justify-center px-8'>
        <h1 className='text-3xl'>Uh, oh!</h1>
        <p className='pt-4 text-center font-light'>{t('404.message')}</p>
        {/* eslint-disable @next/next/no-html-link-for-pages */}
        <a
          href='/'
          className='mt-6 rounded-full border-2 bg-purple py-2 px-6 text-sm font-bold shadow-md'
        >
          {t('404.button')}
        </a>
      </div>
    </div>
  )
}

export default React.memo(NotFoundPage)
